html,
body,
#root,
#app > div {
  height: 100%;
  font-family: 'Telegraf', sans-serif;
}

body {
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  font-size: 0.875rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: #fafafa;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
}

button {
  outline: none !important;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.app-loader {
  z-index: 9999;
  position: fixed;
  top: 0;
  width: 100%;
  height: 2px;
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: local('Inter'),
    url(./resources/fonts/Inter-Regular.woff) format('opentype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: local('Inter'),
    url(./resources/fonts/Inter-Medium.woff) format('opentype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: local('Inter'),
    url(./resources/fonts/Roboto-Regular.ttf) format('opentype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: local('Inter'),
    url(./resources/fonts/Roboto-Medium.ttf) format('opentype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: local('Poppins'),
    url(./resources/fonts/Poppins-Regular.ttf) format('opentype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  src: local('Poppins'),
    url(./resources/fonts/Poppins-Medium.ttf) format('opentype');
}
